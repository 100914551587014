<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <PageHead :title="title" :head="head" />
      <Body>
        <div class="flex h-screen overflow-hidden font-inter">
          <slot name="sidebar">
             <NavigationWhiteSidebar/>
          </slot>
          <div id="main-content" class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden min-h-max">
            <slot name="header">
            </slot>
            <main class="grow bg-slate-100">
              <div class="px-4 sm:px-6 lg:px-8 py-8 lg:py-10 w-full max-w-7xl mx-auto">
                <slot>
                </slot>
              </div>
            </main>
            <slot name="footer">
            </slot>
          </div>
        </div>
        <DialogFeedback :open="feedbackFormDialog"/>
      </Body>
  </Html>
</template>

<script setup>
  import {useUtilsStore} from "~/store/utils";
  import {storeToRefs} from "pinia";

  const utilsStore = useUtilsStore();
  const {feedbackFormDialog} = storeToRefs(utilsStore);
  const route = useRoute()
  const { t } = useI18n();

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
  })
  const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))
</script>

<style scoped>

</style>